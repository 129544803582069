import React, { useRef } from 'react'
import Header from '../components/header/Header'
import Hero from '../components/hero/Hero'
import About from '../components/about/About'
import Services from '../components/services/Services'
import Policy from '../components/policy/Policy'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'
import Partners from '../components/partners/Partners'

const LandingPageLayout = () => {

  const aboutRef = useRef()
  const serviceRef = useRef()
  const policyRef = useRef()
  const contactRef = useRef()
  return (
    <>
      <Header
        aboutRef={aboutRef}
        serviceRef={serviceRef}
        policyRef={policyRef}
        contactRef={contactRef}
      />
      <Hero 
        serviceRef={serviceRef} 
        contactRef={contactRef}
      />
      <Partners />
      <About aboutRef={aboutRef} />
      <Services serviceRef={serviceRef} />
      <Policy policyRef={policyRef} />
      <Contact contactRef={contactRef} />
      <Footer
         aboutRef={aboutRef}
         serviceRef={serviceRef}
         policyRef={policyRef}
         contactRef={contactRef}
       />
    </>
  )
}

export default LandingPageLayout
