import React from 'react'
import "./landing-page.css"
import LandingPageLayout from '../../features/landingPage/layout/LandingPageLayout'

const LandingPage = () => {
  return (
    <main>
        <LandingPageLayout />
    </main>
  )
}

export default LandingPage
