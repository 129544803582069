import React, { useState } from 'react'
import "./contact.css"
import Button from '../../../../components/ui/Button'
import {NavLink} from "react-router-dom"

const Contact = ({contactRef}) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
      })
    
    const handleFormInput = (e) => {
        const {value, name } = e.target;
        setFormData(oldData => {
          return {
            ...oldData,
            [name]: value
          }
        })
    }

    const handleSubmit = async () => {

    }

  return (
    <section className="contact" ref={contactRef}>

      <div className="contact">
      <h2 className="contact">Contact</h2>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.7060750640812!2d3.468520074992114!3d6.431790593559307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5b1e996699b%3A0xa665009897fc5953!2sCanaan%20Plaza!5e0!3m2!1sen!2sng!4v1721062248903!5m2!1sen!2sng" 
          style={{border:0}}
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        

        <form action="https://formspree.io/f/xkgwobqp" method="POST" className="contact-form" onSubmit={handleSubmit} itemProp="potentialAction" itemScope itemType="https://schema.org/CommunicateAction">
          <p className="contact">For any inquiries, questions or commendations, please call; +2348022666585 or fill out the following form:</p>

          <>
            <label className="contact-form-label" htmlFor="name">Name:</label>
            <input name="name" value={formData.name} onChange={handleFormInput} type="text" id="name" placeholder="Enter your name" itemProp="name"/>
          </>
          
          <>
            <label className="contact-form-label" htmlFor="email">Email:</label>
            <input name="email" value={formData.email} onChange={handleFormInput} type="text" id="email" placeholder="Enter your email address" itemProp="email" />
          </>

          <>
            <label className="contact-form-label" htmlFor="message">Message:</label>
            <textarea name="message" value={formData.message} onChange={handleFormInput} id="message" placeholder="Enter your message" itemProp="text"/>
          </>

          <Button
            text="Submit"
            className="contact-form-btn"
          />
        </form>
      </div>
    </section>
  )
}

export default Contact
