import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'
import brandLogo from "../../../../assets/brandLogo.png"

const Footer = ({
  aboutRef,
  serviceRef,
  policyRef,
  contactRef
}) => {
  const scrollToSection = (sectionRef) => {
    sectionRef?.current?.scrollIntoView(true);
  };
  return (
    <footer>
      <hr className="footer" />
      <section className="footer">
        <div>
        <img
          className="footer-brand-logo"
          src={brandLogo}
          alt="Brand Logo"
        />
        </div>

        <addres className="footer">
          <h2 className="footer">Head Office</h2>
          <p className="footer">Suite 4, Canaan Plaza, Goshen Drive</p>
          <p className="footer">Lekki Phase 1, Lagos, Nigeria.</p>
        </addres>

        <nav className="footer">
          <ul className="footer">
            <h2 className="footer">Quick links</h2>
            <li className="footer-list">
              <NavLink onClick={() => scrollToSection(aboutRef)}>About us</NavLink>
            </li>
            <li  className="footer-list">
              <NavLink onClick={() => scrollToSection(serviceRef)}>Services</NavLink>
            </li>
            <li  className="footer-list">
              <NavLink onClick={() => scrollToSection(policyRef)}>Policy</NavLink>
            </li>
            <li  className="footer-list">
              <NavLink onClick={() => scrollToSection(contactRef)}>Contact</NavLink>
            </li>
          </ul>
        </nav>

        {/* <NavLink to="tel:+2349080000641" className="call-now">
          Call Now
        </NavLink> */}
      </section>
    </footer>
  )
}

export default Footer
