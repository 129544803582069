import React, { useEffect, useState } from "react";

const useScrollHeightChange = () => {
  const [scrollHeight, setScrollHeight] = useState(window.scrollY);

  const handleScrollChamge = () => {
    setScrollHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollChamge);

    return () => window.removeEventListener("scroll", handleScrollChamge);
  }, [scrollHeight]);

  return scrollHeight;
};

export default useScrollHeightChange;
