import React from 'react'
import "./partners.css"
import daewoo from "./assests/daewoo.png"
import seplat from "./assests/seplat.png"
import chevron from "./assests/chevron.png"
import platform from "./assests/platform.png"
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver'

const Partners = () => {
    const [divRef, isIntersecting] = useIntersectionObserver()
 
  return (
    <section className="partners">
        <h2 className="partners">Partners</h2>
        <div className={`partner ${isIntersecting ? "visible" : ""}`} ref={divRef}>
            <img 
                className="partners-logo"
                src={chevron} 
                alt="Chevron"
            />
            <img 
                className="partners-logo"
                src={seplat} 
                alt="Seplat"
            />
            <img 
                className="partners-logo"
                src={daewoo} 
                alt="Daewoo"
            />
            <img 
                className="partners-logo"
                src={platform} 
                alt="Platform petroleum"
            />
        </div>
    </section>
  )
}

export default Partners
