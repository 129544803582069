import React from 'react'
import "./services.css"
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver'

const Services = ({serviceRef}) => {
    const [s, isIntersecting] = useIntersectionObserver()
  return (
    <section className="service" ref={serviceRef}>
        <h2 className="service">Services</h2>
        
        <div className={`service ${isIntersecting ? "visible" : ""}`} ref={s}>
        <ul className={`services-list`}>
            <li className="list-item-service">
                <img 
                    src="https://img.freepik.com/free-photo/pollution-industry-exterior-daylight_23-2149057674.jpg?t=st=1721007524~exp=1721011124~hmac=3b7e55f67ff44a8ae323479059063182d7ade769122c678a670b2cd4d9c902d1&w=740"
                    alt="" 
                    className="service"
                />
                <div className="service-description">
                    <h3 className="list-item-service-title">Engineering Services</h3>
                    <p>Pipeline and production facilities engineering.</p>
                </div>
            </li>
            <li className="list-item-service">
                <img 
                    src="https://img.freepik.com/free-photo/african-american-worker-standing-uniform-wearing-safety-hat-factory_1303-30610.jpg?t=st=1721398418~exp=1721402018~hmac=15935e4556f322727ea0dc1c672425248c817e7b2b20f948353aea97beed416e&w=900"
                    alt="" 
                    className="service"
                />
                <div className="service-description">
                    <h3 className="list-item-service-title">Procurement Services</h3>
                    <p>Strategic sourcing, cost optimization, supplier relationship management.</p>
                </div>
            </li>
            <li className="list-item-service">
                <img 
                    src="https://img.freepik.com/free-photo/medium-shot-engineer-wearing-equipment_23-2149354008.jpg?t=st=1721405012~exp=1721408612~hmac=ace430ceba4f1eb824eb79e13a1915fddad76db5d32bfba5b9652e2ca69f9c37&w=900"
                    alt="" 
                    className="service"
                />
                <div className="service-description">
                    <h3 className="list-item-service-title">Logistics Services</h3>
                    <p>Efficient logistics and supply chain operations.</p>
                </div>
            </li>
            <li className="list-item-service">
                <img 
                    src="https://img.freepik.com/free-photo/monochrome-scene-depicting-life-workers-construction-industry-site_23-2151431433.jpg?t=st=1721405116~exp=1721408716~hmac=8c9f8c0112f6d9b1486d4609ddea9320358f2209b405b73d0b06c2a10f5ef979&w=1060"
                    alt=""
                    className="service" 
                />
                <div className="service-description">
                    <h3 className="list-item-service-title">Environmental Services</h3>
                    <p>Waste management, pollution control, environmental studies.</p>
                </div>
            </li>
        </ul>
        </div>

        {/* <ul className="core">
            <div>
            </div>
            <h2>Core Services</h2>
            <li>Ball valves, mud gate valves, gate valves, globe valves, check valves, butterfly valves, cryogenic valves.</li>
            <li>Gas Turbine Parts: Turbo projects, turboprops, afterburning turbojets, turbofans.</li>
            <li>Hoisting and Lifting Equipment: Lifting clamps, hoists, beam clamps, winches, steel wire ropes, slings, trolleys, hydraulic lifts.</li>
            <li>Lamps and Luminaries: Fluorescent lamps, electrical discharge lamps, tungsten halogen, incandescent lamps.</li>
            <li>Compressors: Scroll compressors, centrifugal compressors, screw compressors, reciprocating compressors, piston compressors.</li>
            <li>Flanges: Welding neck, slip-on, blind, lap-joint, socket weld, orifice.</li>
            <li>Auxiliary Plants and Components: Air heaters, fluidized bed systems, ash handling systems, igniters, coal burners, oil/natural gas burners, cyclone furnaces.</li>
            <li>Electric Motor Accessories and Parts: Flat sensor wires, quasar brushes, replacement rotors, serrated brushes, resistor throttle controls, brushes and springs.</li>
            <li>Vacuum Pumps: Liquid ring, rotary vane, rotary piston, dry vacuum pumps, vacuum boosters.</li>
            <li>Additional Equipment: Instrumentation, electrodes, welding machines, tubular goods, bolts and nuts, laboratory requisites, goods wrappers, safety materials such as gas detectors.</li>
        </ul> */}
      
    </section>
  )
}

export default Services
