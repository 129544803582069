import React, { useEffect, useState } from 'react'
import "./hero.css"

const Hero = ({serviceRef, contactRef}) => {
  const [offset, setOffset] = useState(0);
  
  useEffect(() => {
      const handleScroll = () => {
        setOffset(window.scrollY)
      }

      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const scrollToSection = (sectionRef) => {
    sectionRef?.current?.scrollIntoView(true);
  };

  return (
    <section className="hero" style={{ opacity: 1 - offset * 0.00197}}>
      <img
        className="hero-img"
        src="https://images.pexels.com/photos/3855962/pexels-photo-3855962.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        // src="https://images.unsplash.com/photo-1600221574280-9bcd5d108100?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />

      <article className="hero-content">

        {/* <div> */}
        <h1 className="hero-title">Excellence in Oil & Gas Services</h1>
        <p>Engineering | Procurement | Logistics | Environmental Services</p>
        {/* <h3>Your Trusted Partner in Oil and Gas Procurement</h3> */}
        {/* </div> */}



        <div className="hero-btn-container">
          <button className="hero-btn" onClick={() => scrollToSection(serviceRef)}>Services</button>
          <button className="hero-btn" onClick={() => scrollToSection(contactRef)}>Contact</button>
        </div>
      </article>
    </section>
  )
}

export default Hero
