import React, { useEffect, useState } from 'react'
import "./header.css"
import {NavLink} from "react-router-dom"
import useWindowWidth from '../../../../hooks/useWindowWidth'
import Button from '../../../../components/ui/Button'
import useScrollHeightChange from '../../../../hooks/useScrollHeight'
// import one from "./fbBg.png"
import brandLogo from "../../../../assets/brandLogo.png"

const Header = ({
  aboutRef,
  serviceRef,
  policyRef,
  contactRef
}) => {
    const [showNav, setShowNav] = useState(false)
    const screenWidth = useWindowWidth()
    const scrollHeight = useScrollHeightChange()
    const isMobile = screenWidth <= 767

    useEffect(() => {
        if(!isMobile){
            setShowNav(true)
          }
        else{
            setShowNav(false)
        } 
    }, [screenWidth])

    const scrollToSection = (sectionRef) => {
      sectionRef?.current?.scrollIntoView(true);
    };

  return (
    <header className={`header-container ${scrollHeight > 10 && "ch"}`}>
        <img
          className="logo"
          src={brandLogo}
          alt="Brand Logo"
        />

        <nav className="nav-container">
           {showNav && <ul className={`${isMobile ? "nav-overlay" : ""}  nav-list`}>
               { isMobile && <li className="nav-list-item">
                    <Button
                        onClick={() => setShowNav(false)}
                        ariaLabel="Close navigation menu"
                        text={
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 384 512" fill='#fff'>
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                            </svg>
                        }
                    />
                </li>}
                <li className="nav-list-item" onClick={() => isMobile && setShowNav(false)}>
                    <NavLink onClick={() => scrollToSection(aboutRef)}>About Us</NavLink>
                </li>
                <li className="nav-list-item" onClick={() => isMobile && setShowNav(false)}>
                    <NavLink onClick={() => scrollToSection(serviceRef)}>Services</NavLink>
                </li>
                <li className="nav-list-item" onClick={() => isMobile && setShowNav(false)}>
                    <NavLink onClick={() => scrollToSection(policyRef)}>Policy</NavLink>
                </li>
                <li className="nav-list-item" onClick={() => isMobile && setShowNav(false)}>
                    <NavLink onClick={() => scrollToSection(contactRef)}>Contact</NavLink>
                </li>
                <li className="nav-list-item" onClick={() => isMobile && setShowNav(false)}>
                    <NavLink to="tel:+2348022666585">Call Now</NavLink>
                </li>
            </ul>}
        </nav>

        {isMobile && !showNav &&
        <Button
          className="nav-button"
          text={
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512">
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
            </svg> 
          }
          onClick={() => setShowNav(oldvalue => !oldvalue)}
          ariaLabel="Open navigation menu"
          ariaExpanded={showNav}
        />
      }

    </header>
  )
}

export default Header
