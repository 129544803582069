import React from 'react'
import "./policy.css"
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver'

const Policy = ({policyRef}) => {
    const [ulRef, isIntersecting] = useIntersectionObserver()
  return (
    <section className="policy" ref={policyRef}>
        <div className="policy-bg">
            <img 
                src="https://img.freepik.com/free-photo/steel-pipelines-cables-plant_1359-145.jpg?t=st=1721007843~exp=1721011443~hmac=b973cf412d4d88f6a5ae4a49b703af676778c57b341003b06edf3282cc59b0c9&w=740"
                alt="" 
                className="policy"
            />  
        </div>

        <ul className={`policy-list ${isIntersecting && "slide-in"}`} ref={ulRef}>
            <li className="list-item-policy">
                <h2 className="policy">Quality Policy</h2>
                <p className="policy-text">Quality and continuous improvement are central to our ethos. We are committed to delivering services that meet agreed customer needs and expectations within time and budget constraints.</p>
            </li>

            <li className="list-item-policy">
                <h2 className="policy">Procurement on the Spotlight</h2>
                <p className="policy-text">Effective procurement involves evaluating spend, identifying non-core activities, and optimizing resources to generate value and savings.</p>
            </li>

            <li className="list-item-policy">
                <h2 className="policy">HSE Policy</h2>
                <p className="policy-text">
                    Our policy is to secure the health and safety of our employees, contractors, the public, and the environment. Safety is a priority, and every member of our workforce is responsible for maintaining safety standards both on and off the job.
                </p>
            </li>

            <li className="list-item-policy">
                <h2 className="policy">Community Relations Policy</h2>
                <p className="policy-text">
                    FlamePact Limited recognizes the impact of our activities on local communities. We engage constructively with the community, promote education and training, respect local cultural and religious needs, and provide relevant information about our activities.
                </p>
            </li>
      
            <li className="list-item-policy">
                <h2 className="policy">Financial Capability</h2>
                <p className="policy-text">
                    FlamePact Limited has a strong financial base, with stable working capital and excellent financial records. Our bankers hold us in high esteem and are willing to extend unlimited credit facilities due to our proven capabilities and management flexibility.
                </p>
            </li>
        </ul>
    </section>
  )
}

export default Policy
