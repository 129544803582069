import React, { useEffect, useRef, useState } from 'react'
import "./about.css"
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';

const About = ({aboutRef}) => {

    const [ulRef, isIntersecting] = useIntersectionObserver()

  return (
    <section className="about" ref={aboutRef}>
        <div className="about">
            <div>
                <img 
                    src="https://img.freepik.com/free-photo/aerial-view-gas-oil-refinery-oil-industry_335224-1376.jpg?ga=GA1.1.1991276647.1688005309&semt=ais_user" 
                    alt=""
                    className="about-img" 
                />
            </div>

            <ul className={`about-list ${isIntersecting ? "visible" : ""}`} ref={ulRef}>
                <li className="about-list-item">
                    <h2 className="about-list-item-title">Introduction</h2>
                    <p className="about-list-item-text">
                        FlamePact Limited is a fully Nigerian-owned company based in Lagos, Nigeria, specializing in providing services across the oil and gas sector, including pipeline and production facilities engineering, procurement and logistics services, environmental studies, waste management, and pollution control.
                    </p>
                </li>
                <li className="about-list-item">
                    <h2 className="about-list-item-title">Vision Statement</h2>
                    <p className="about-list-item-text">
                        To become a world-class company capable of designing, constructing, and maintaining related equipment and facilities, participating in supply chain management services, and positively impacting the environment and our communities.
                    </p>
                </li>
                <li className="about-list-item">
                    <h2 className="about-list-item-title">Mission Statement</h2>
                    <p className="about-list-item-text">
                        Our mission is to provide goods and services to the oil and gas industries using best practices in engineering and logistics while protecting the environment.
                    </p>
                </li>
                <li className="about-list-item">
                    <h2 className="about-list-item-title">Core Values</h2>
                    <p className="about-list-item-text">
                        Excellence, integrity, and quality performance.
                    </p>
                </li>
            </ul>
        </div>
    </section>
  )
}

export default About
